/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
html,
body div {
  font-family: "Poppins", sans-serif;
}
.color-blue {
  color: #101e45;
}
.bg-grey {
  background-color: #F8F9FA;
}
/* ========== Scrollbar ========== */
/* width 
::-webkit-scrollbar {
  width: 5px;
}*/

/* Track 
::-webkit-scrollbar-track {
  background: #f1f1f1;
}*/

/* Handle 
::-webkit-scrollbar-thumb {
  background: #888;
}*/

/* Handle on hover 
::-webkit-scrollbar-thumb:hover {
  background: #555;
}*/
/* ============================== */
.top-image {
  min-height: 150px;
  background-image: url("../public/images/background-1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.img-column {
  height: 75px;
}
.profile-image {
  height: 150px;
  width: 150px;
  position: relative;
  top: -75px;
  border: 4px solid white;
  object-fit: cover;
  border-radius: 50%;
}
/* ========== Edit Button ========== */
.edit {
  margin: 10px;
  padding: 5px;
  color: #555;
  transition: 0.4s;
  border: none;
}
.edit:hover {
  color: #101e45;
  background-color: #ffffff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1) !important;
}

/* ========== Tag ========== */
.tag {
  padding: 2px 10px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
}
.technology {
  padding: 2px 10px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
}
.dropdown-list{
  min-height: 200px;
  overflow-y: scroll;
}
.delete{
  transition: 0.4s;
}
.delete:hover {
  color: #DC3545;
  background-color: #ffffff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1) !important;
}



/* ========== PDF ========== */

.font-bold{
  font-size: 40px;
  font-weight: 900;
}
.font-thin {
  font-size: 40px;
  font-weight: 100;
}
.firstname{
  position: relative;
  top: 20px;
}
.title{
  font-weight: 600;
  letter-spacing: 1px;
}
.bg-1 {
  background-color: antiquewhite;
}
.bg-2 {
  background-color: rgb(49, 49, 49);
}
.bg-3 {
  background-color: rgb(77, 77, 77);
}
.pdf{
  width: 210mm;
  height: 297mm;
}
.pdf-profile-image {
  height: 150px;
  width: 150px;
  border: 4px solid white;
  object-fit: cover;
  border-radius: 50%;
}






#label1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  width: 800px;
  line-height: 25px;
  /* margin-bottom: 10px; */
  /* margin-left: 0; */
}

#input1 {
  height: 30px;
  /* flex: 0 0 200px; */
  margin-left: 10px;
  width: 12em;
}

#form1 {
  align-items: start;
  justify-content: start;
}

#label2{
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  width: 400px;
  line-height: 26px;
  margin-bottom: 10px;
}

#input2{
  height: 20px;
  flex: 0 0 200px;
  margin-left: 10px;
  width: 100px;
}

#button2{
  margin-right: 10px;
}

span {
  margin-left: 15px;
}

#input3 {
  height: 35px;
  /* flex: 0 0 200px; */
  margin-left: 10px;
  width: 11em;
  /* font-size: 12px; */
}

#checkbox {
  height: 20px;
  width: 20px;
  margin-left: 10px;
  margin-top: 2px;
}

#file {
  /* height: 20px;
  width: 20px; */
  margin-left: 10px;
  /* margin-top: 2px; */
}

